@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background-color: #FFFFFF !important;
}

.main-container {
    min-height: 350px;
}

.survey-chart-card {
    margin-bottom: 40px;
    border-radius: 12px;
    padding: 18px;
    height: 500px;
    display: flex;
    padding: 24px;
    justify-content: center;
}
