button,
button::after,
button::before,
button:focus,
button:active,
button:focus:active {
    outline: none;
    box-shadow: none;
    border-color: transparent !important;
}

.wrapper {
    display: block;
    width: 100%;
}

.header {
    background-color: #060B27;
    display: flex;
    width: 100%;
    min-height: 70px;
    align-items: center;
    font-family: 'Inter', sans-serif;
    border-bottom: 1px solid #151934;
}

.header .navbar ul {
    display: flex;
    padding: 0;
    margin-bottom: 0;
}

.header .navbar ul li.active a {
    color: #FFFFFF;
}

.header .navbar ul li {
    list-style: none;
    margin-right: 27px;
    font-size: 14px;
    line-height: 20px;
    color: #8F9BB7;
    cursor: pointer;
}

.header .navbar ul li a:hover {
    color: #FFFFFF;
}

.header .navbar ul li a {
    text-decoration: none;
    color: #8F9BB7;
}

.header .btn {
    border-radius: 30px;
    background-color: #7214FF;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
}

.main-banner {
    padding: 40px 0px 60px 0px;
    background-color: #060B27;
    font-family: 'Inter', sans-serif;
}

.main-banner .cost-img {
    width: 500px;
    height: 400px;
}

.main-banner .cost-contain span {
    font-size: 33px;
    line-height: 40px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.main-banner .cost-contain p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    margin-top: 20px;
}

.header-sticky {
    position: absolute;
    top: -100%;
    right: 0;
    left: 0;
    z-index: 11;
    display: block;
    width: 100%;
    padding: 8px 0;
    background-color: #ffffff;
    border-bottom: 1px solid #d0d0d0;
    transition: all .5s;

}

.header-sticky.is-sticky {
    position: fixed;
    top: 0;
    padding: 8px 0;
    transition: all .5s;
    backdrop-filter: blur(10px);
    animation: slideDown 0.95s ease-out;
}

.logo-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.sharetoolbar ul {
    display: flex;
    list-style: none;
    width: auto;
    padding: 0;
    margin: 0;
}

.sharetoolbar ul li {
    display: inline-flex;
    padding-left: 0px;
}

.sharetoolbar ul li button {
    font-size: 12px;
    font-weight: 300;
}

.sharetoolbar ul li button i {
    display: flex;
    justify-content: center;
}

#progressBarContainer {
    position: fixed;
    z-index: 12;
    width: 100%;
    top: 60px;
    left: 0;
    opacity: 0;
}

#progressBar {
    background-color: transparent;
    background: rgb(134, 118, 255);
    transform-origin: top left;
    transform: scale(0, 0);
    opacity: 0;
}

#progressBarContainer,
#progressBar {
    height: 2px;
}

.header-sticky.is-sticky+#progressBarContainer {
    opacity: 1;
}

.cost-main-container {
    display: block;
    width: 100%;
    margin-bottom: 40px;
}

.tabs-container {
    display: block;
    margin-top: 60px;
}

.tabs-container .nav-pills {
    display: block;
}


.tabs-container .nav-pills .nav-item {
    display: inline-flex;
    position: relative;
    width: 223px;
    height: 55px;
}

.tabs-container .nav-pills .nav-item .nav-link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
    font-size: 15px;
    padding-left: 33px;
    line-height: 25px;
    font-weight: 500;
    color: #000000;
    background-color: #C6CBD9;
    transition: color 0s ease-in-out, background-color 0s ease-in-out, border-color 0s ease-in-out;
}

.tabs-container .nav-pills .nav-item .nav-link svg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 100%;
    height: 100%;

}

.tabs-container .nav-pills .nav-item .nav-link svg #background {
    color: transparent;
}

.tabs-container .nav-pills .nav-item .nav-link svg #block {
    color: #C6CBD9;
}

.tabs-container .nav-pills .nav-item .nav-link.active svg #block {
    color: rgba(134, 118, 255, 1);
}

.tabs-container .nav-pills .nav-item .nav-link svg #arrow {
    color: white;
}


.tabs-container .nav-pills .nav-item .nav-link.active {
    color: #ffffff;
    background-color: rgba(134, 118, 255, 1);
}

.tabs-container .content {
    display: block;
    padding: 30px 20px 10px;
    margin: 25px 0px 35px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(249, 251, 255, 1);
}

.tabs-container .content small {
    display: block;
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;

}

.tabs-container .scenario-services {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 30px;

}

.tabs-container .scenario-services .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;

}

.tabs-container .scenario-services.other .heading {
    border-bottom: 1px solid rgb(179, 179, 179);
}

.tabs-container .scenario-services.other .heading strong {
    color: #FFFFFF;
}

.tabs-container .scenario-services.other .heading button {
    color: #FFFFFF;
}

.tabs-container .scenario-services .heading strong {
    display: inline-flex;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    color: #ffffff;
}

.tabs-container .scenario-services .heading button {
    display: inline-flex;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #ffffff;
    padding: 0;
}

.tabs-container .scenario-services .heading button span {
    border-bottom: 1px solid #FFFFFF;
}

.tabs-container .scenario-services .heading button i {
    line-height: 32px;
    margin-left: 5px;
}

.tabs-container .scenario-services .heading button i.fa-chevron-up {
    line-height: 20px;
    vertical-align: top;
    margin-top: 7px;
}

.tabs-container .scenario-services .service-content {
    display: block;
    padding: 24px 72px 24px 24px;
    background-color: #F9FBFF;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0px 15px 15px 0px rgba(108, 73, 172, 0.2);

}

.tabs-container .scenario-services.other .service-content {
    background-color: #F9FBFF;
}

.tabs-container .scenario-services .service-content .open-content {
    display: block;
}

.tabs-container .scenario-services .service-content .open-content span {
    display: block;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    font-size: 17px;
    line-height: 30px;
}

.tabs-container .scenario-services .service-content .open-content .service-details {
    display: block;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
}

.tabs-container .scenario-services .service-content .contents {
    font-size: 18px;
    line-height: 28px;
    border-top: 1px solid rgb(179, 179, 179);
    margin-top: 24px;
    padding-top: 24px;
    letter-spacing: 0.01em;
    display: block;
}

.tabs-container .content .sub-heading {
    display: block;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.01em;
    color: rgb(5, 28, 44);
    margin-top: 0px;
    margin-bottom: 16px;
}

.tabs-container .content .link {
    border: none;
    margin: 12px 0px 0px;
    padding: 0px;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font-family: inherit;
    -webkit-font-smoothing: inherit;
    appearance: none;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    transition: color 0.2s ease 0s;
    font-weight: 500;
    line-height: 1;
    font-size: 18px;
}

.tabs-container .content .link:hover {
    color: rgb(34, 81, 255);
}

.tabs-container .content .link svg {
    transition: transform 0.2s ease 0s;
    transform: translateX(8px);
    color: rgb(34, 81, 255);
    width: 21px;
    height: 15px;
}

.tabs-container .content .link:hover svg {
    transform: translateX(24px);
}

.tabs-container .optimization-content {
    display: block;
    padding-top: 40px;
    padding-bottom: 32px;
}

.tabs-container .optimization-option-tab .nav-pills {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(179, 179, 179);
}

.tabs-container.tabs-sticky .optimization-option-tab .nav-pills {
    top: 121px;
    padding-top: 0;
}

.tabs-container .optimization-option-tab .nav-pills .nav-item {
    display: inline-flex;
    width: 180px;
    height: 45px;
}

.tabs-container .optimization-option-tab .next-btn {
    background-color: #384CFF;
    width: 60px;
    height: 38px;
}

.tabs-container .optimization-option-tab .nav-pills .nav-item .nav-link {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #383874;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: none;
}

.tabs-container .optimization-option-tab .nav-pills .nav-item .nav-link.active {
    border-bottom: 2px solid #8676FF;
    border-radius: 5px, 5px, 0px, 0px;
}

.tabs-container .optimization-option-tab .tab-content {
    background-color: #F9FBFF;
}

.tabs-container .optimization-option-tab .tab-content .tab-pane {
    padding: 24px 24px 50px 24px;
    border-radius: 5px;
}


.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost {
    width: 100%;
    min-height: 30px;
    margin-top: 15px;
    background: #F0F3FF;
    height: 50px;
    border-radius: 4px;
    padding: 8px;
}


.tabs-container .optimization-option-tab .tab-content .compute-contain.storage .compute-cost::before {
    background-color: rgb(0, 169, 244);
}

.tabs-container .optimization-option-tab .tab-content .compute-contain.network .compute-cost::before {
    background-color: rgb(3, 75, 111);
}

.tabs-container .optimization-option-tab .tab-content .compute-contain.managed .compute-cost::before {
    background-color: rgb(2, 122, 177);
}


/* .tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost .progress {
 
    width: 100%;
    transition: width 0.3s ease 0s, background 0.3s ease 0s;
    position: relative;
    border-radius: 0;
} */

.tabs-container .optimization-option-tab .tab-content .compute-contain.storage .compute-cost .progress {
    background-color: rgb(0, 169, 244);
}

.tabs-container .optimization-option-tab .tab-content .compute-contain.network .compute-cost .progress {
    background-color: rgb(3, 75, 111);
}

.tabs-container .optimization-option-tab .tab-content .compute-contain.managed .compute-cost .progress {
    background-color: rgb(2, 122, 177);
}

.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost span {
    padding-bottom: 8px;
    display: block;
    color: #383874;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
}

.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost .progress {

    height: 8px;
    position: relative;
}

.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost .progress::before,
.progress::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost .progress::before {
    width: 100%;
    background: #DBDFF1;
}

.tabs-container .optimization-option-tab .tab-content .compute-contain .compute-cost .progress div {
    width: 75%;
    background-color: #FF708B;
    height: 8px;
    display: block;
    position: relative;
    z-index: 1;
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn {
    padding: 0;
    font-size: 16px;
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn span {
    font-size: 16px;
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn i {
    margin-left: 5px;
    color: rgb(34, 81, 255);
    vertical-align: middle;
    transition: transform 0.2s ease 0s;
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn:hover i {
    transform: translateX(16px);
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn:hover span {
    color: rgb(34, 81, 255);
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .total {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: rgb(5, 28, 44);
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .number {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #383874;
}

.tabs-container .optimization-option-tab .tab-content .compute-total-cost .compair {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #383874;
}

.tabs-container .optimization-option-tab .tab-content .tab-pane .tabs-storage-charts {
    position: relative;
    padding: 24px;
    border: none;
    box-shadow: 0px 100px 80px 0px #6C49AC12;
}

.tabs-container .optimization-option-tab .tab-content .storage-contain {
    margin: 10px 0px;
}


.tabs-container .optimization-option-tab .tab-content .storage-contain .storage-cost {
    background: rgb(0, 169, 244);
    width: 100%;
    min-height: 30px;
    align-items: center;
    display: flex;
    margin-top: 5px;
}

.tabs-container .optimization-option-tab .tab-content .storage-contain .storage-cost span {
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    line-height: 20px;
}

.tabs-container .optimization-option-tab .tab-content .storage-total-cost span {
    font-weight: 500;
    font-size: 20px;
    color: rgb(5, 28, 44);
}


.tabs-container .optimization-option-tab .tab-content .network-contain {
    margin: 10px 0px;
}


.tabs-container .optimization-option-tab .tab-content .network-contain .network-cost {
    background: rgb(3, 75, 111);
    width: 100%;
    min-height: 30px;
    align-items: center;
    display: flex;
    margin-top: 5px;
}

.tabs-container .optimization-option-tab .tab-content .tab-pane .tabs-network-charts {
    position: relative;
    padding: 24px;
    border: none;
    box-shadow: 0px 100px 80px 0px #6C49AC12;
}

.tabs-container .optimization-option-tab .tab-content .network-contain .network-cost span {
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    line-height: 20px;
}

.tabs-container .optimization-option-tab .tab-content .network-total-cost span {
    font-weight: 500;
    font-size: 20px;
    color: rgb(5, 28, 44);
}

.tabs-container .optimization-option-tab .tab-content .tab-pane .tabs-managed-charts {
    position: relative;
    padding: 24px;
    border: none;
    box-shadow: 0px 100px 80px 0px #6C49AC12;
}

.tabs-container .optimization-option-tab .tab-content .managed-database-contain {
    margin: 10px 0px;
}


.tabs-container .optimization-option-tab .tab-content .managed-database-contain .managed-database-cost {
    background: rgb(2, 122, 177);
    width: 100%;
    min-height: 30px;
    align-items: center;
    display: flex;
    margin-top: 5px;
}

.tabs-container .optimization-option-tab .tab-content .managed-database-contain .managed-database-cost span {
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    line-height: 20pox;
}

.tabs-container .optimization-option-tab .tab-content .managed-database-total-cost span {
    font-weight: 500;
    font-size: 20px;
    color: rgb(5, 28, 44);
}

.tabs-container .optimization-option-tab .tab-content .apply-box {
    background: rgb(240, 240, 240);
    color: rgb(5, 28, 44);
    width: 100%;
    margin-top: 25px;
}

.tabs-container .optimization-option-tab .tab-content .top-contain {
    padding: 25px;
    background-color: #3697EF;
    height: 50px;
    display: block;
    border-radius: 5px;

}

.tabs-container .optimization-option-tab .tab-content .top-contain span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.tabs-container .optimization-option-tab .tab-content .top-contain .form-switch .form-check-input {
    width: 37px;
    padding: 4px;
    cursor: pointer;
    background-color: #B7B7B7;
    height: 17px;
    border: none;
}

.tabs-container .optimization-option-tab .tab-content .top-contain .form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.tabs-container .optimization-option-tab .tab-content .top-contain .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.tabs-container .optimization-option-tab .tab-content .top-contain .form-switch .form-check-input:checked {
    background-color: rgb(34, 81, 255);
}

.tabs-container .optimization-option-tab .tab-content .top-contain .form-switch .form-check-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding-left: 15px;
}


.tabs-container .optimization-option-tab .tab-content .bottom-contain {
    padding: 25px;
    display: block;
    border-radius: 0px 0px 7px 7px;
    background-color: #FFFFFF;
    box-shadow: 0px 100px 80px 0px #6C49AC12;

}

.tabs-container .optimization-option-tab .tab-content .bottom-contain span {
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
}

.tabs-container .optimization-option-tab .tab-content .bottom-contain .impact-details {
    color: #1B4DFFED;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    display: inline-block;
    text-align: right;
}

.tabs-charts {
    position: relative;
    padding: 24px;
    border: none;
    box-shadow: 0px 100px 80px 0px #6C49AC12;

}



/* .tabs-charts::before,
.tabs-charts::after {
    content: " ";
    display: block;
    position: absolute;
    top: 100%;
    height: 6px;
    left: 0px;
    background: linear-gradient(90deg, rgb(255, 255, 255) 75%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    width: calc((100% - 1000px) / 2);
} */

/* .tabs-charts::after {
    right: 0px;
    left: auto;
    background: linear-gradient(-90deg, rgb(255, 255, 255) 75%, rgba(255, 255, 255, 0) 100%);
} */



.modal .modal-dialog {
    width: 100%;
    display: flex;
    height: calc(100% - 61px);
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.modal .modal-dialog .modal-content {
    height: 500px;
    overflow-y: auto;
    background-color: #051c2c;
}

.modal .modal-dialog .modal-content .modal-header {
    border-bottom: none;
}

.modal .modal-dialog .modal-content .modal-header .btn-close {
    background-color: #ffffff;
}

.modal .modal-dialog .modal-content .modal-body {
    padding: 0px 60px 20px 60px;
}

.modal .modal-dialog .modal-content .modal-body .modal-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 15px;

}

.modal .modal-dialog .modal-content .modal-body .notice-point span {
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: white;
    margin-top: 15px;
}

.modal .modal-dialog .modal-content .modal-body .notice-point ul {

    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: white;
}

.modal .modal-dialog .modal-content .modal-body .notice-point ul li {
    margin-top: 10px;
}

.modal .modal-dialog .modal-content .modal-body .range-info {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    margin-bottom: 12px;
}

.modal .modal-dialog .modal-content .modal-body .range-info .range-box {
    background-color: rgb(0, 169, 244);
    height: 30px;
}

.modal .modal-dialog .modal-content .modal-body .range-info .range-percentage .percentage span {
    font-weight: 400;
    font-size: 16px;
    color: white;
}

.tabs-container .tab-content .tab-pane .final-impact-content {
    display: block;
    padding-top: 40px;
    padding-bottom: 32px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding-right: 12px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span::before {
    position: absolute;
    content: " ";
    left: 0px;
    top: 6px;
    width: 7px;
    height: 7px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span.computer::before {
    background: rgb(5, 28, 44);
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span.stronge::before {
    background: rgb(0, 169, 244);
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span.network::before {
    background: rgb(3, 75, 111);
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span.managed-database::before {
    background: rgb(2, 122, 177);
}

.tabs-container .tab-content .tab-pane .final-impact-part .impact-details span.other-services::before {
    background: rgb(208, 208, 208);
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total {
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 28px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details span {
    margin: 2px 0px;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 15px;
    font-weight: 300;
    line-height: 20px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details .computer {
    border-left: 2px solid rgb(5, 28, 44);
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details .stronge {
    border-left: 2px solid rgb(0, 169, 244);
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details .network {
    border-left: 2px solid rgb(3, 75, 111);
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details .managed-database {
    border-left: 2px solid rgb(2, 122, 177);
}

.tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details .other-services {
    border-left: 2px solid rgb(208, 208, 208);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-details {
    font-weight: 500;
    font-size: 20px;
    margin-top: 50px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-header {
    background: rgb(5, 28, 44);
    padding: 12px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-header .optimization-title {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-header span {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body {
    padding: 18px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background-color: #f0f0f0;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .name {
    color: rgb(0, 0, 0);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .scenario-number {
    color: rgb(127, 127, 127);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .final-impact {
    color: rgb(0, 0, 0);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .utilize-optimization {
    font-weight: 400;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
    margin-left: 5px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .utilize-optimization:hover {
    border-bottom: 1px solid black;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .add-optimization {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: rgb(34, 81, 255);
    cursor: pointer;

}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .add-optimization span {
    margin-left: 5px;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table .table-body .add-optimization span:hover {
    border-bottom: 1px solid #2251ff;
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table.storage .table-header {
    background: rgb(0, 169, 244);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table.network .table-header {
    background: rgb(3, 75, 111);
}

.tabs-container .tab-content .tab-pane .final-impact-part .optimization-table.managed-database .table-header {
    background: rgb(2, 122, 177);
}

.tabs-container .tab-content .tab-pane .final-impact-part .other-services-info {
    background: rgb(208, 208, 208);
    padding: 12px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
}

.tabs-container .tab-content .tab-pane .final-impact-part .other-services-info span {
    color: black;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
}

.tabs-container .tab-content .tab-pane .final-impact-part .other-services-info p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 0px;
    color: rgb(0, 0, 0);
    width: 69%;
}


.footer {
    background-color: #060B27;
    padding: 40px 0px;
    background-image: url("../img/shadow.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1200px 400px;
}

.footer .menu-list {
    border-bottom: 1px solid #282D45;

}

.footer .menu-list .subscribe-info {
    width: 280px;
    background-color: #0E1330;
    border: 1px solid #282D45;
    border-radius: 5px;
    padding: 20px 0px;
    margin-top: 30px;
}

.footer .menu-list .subscribe-info span {
    font-weight: 700;
    line-height: 25px;
    font-size: 18px;
    color: #F6F6F7;
}

.footer .menu-list .subscribe-info .btn {
    margin-top: 10px;
    border-radius: 30px;
    background-color: #7214FF;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    width: 100%;
    display: block;
    color: #FFFFFF;
}

.footer .menu-list .subscribe-info .email-input {
    background-color: transparent;
    width: 100%;
    outline: none;
    border: 1px solid #282D45;
    border-radius: 40px;
    margin-top: 10px;
    padding: 5px 10px 5px 20px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.footer .menu-list .subscribe-info .email-input::placeholder {
    color: #47507A;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}

.footer .menu-list .menu ul {
    padding-left: 0;
}

.footer .menu-list .menu label {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: #8F9BB7;
    font-weight: 500;
}

.footer .menu-list .menu ul li {
    list-style: none;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
}

.footer .menu-list .menu ul li a {
    text-decoration: none;
    color: #8F9BB7;
    cursor: pointer;
}

.footer .menu-list .menu ul li a:hover {
    color: #FFFFFF;

}

.footer .copyright-details {
    margin-top: 20px;
}

.footer .copyright-details .social-icon ul {
    display: flex;
    padding: 0;
    margin: 0;
}

.footer .copyright-details .social-icon ul li {
    list-style: none;
    margin-left: 20px;
    border: 1px solid #282D45;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.footer .copyright-details .social-icon ul li a {
    color: #8F9BB7;
}

.footer .copyright-details .social-icon ul li:hover {
    border-color: #FFFFFF;


}

.footer .copyright-details .social-icon ul li:hover a {
    color: #FFFFFF;
}

.footer .copyright-details span {
    color: #8F9BB7;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.footer .copyright-details span a {
    text-decoration: none;
    color: #F6F6F7;
}

.architectural-info .next-btn {
    background-color: #384CFF;
    width: 60px;
    height: 38px;
}

.actual-non-actual-charts {
    background-color: #F9FBFF;
    padding: 24px;
}

.actual-non-actual-charts .charts {
    padding: 24px;
    border-radius: 0;
    box-shadow: 0px 4px 4px 0px #00000040;
    ;
}

.actual-non-actual-charts .charts .total-sepnd {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #9F9FDA;
}

.actual-non-actual-charts .charts .number {
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    color: #383874;
}

.actual-non-actual-charts .charts .text {
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #383874;
}

.actual-non-actual-charts .charts .form-switch .form-check-input {
    width: 37px;
    cursor: pointer;
    background-color: #B7B7B7;
    height: 17px;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.actual-non-actual-charts .charts .form-switch .form-check-input:focus {

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");

}

.actual-non-actual-charts .charts .form-switch .form-check-input:checked {
    background-color: #384CFF;
}

.actual-non-actual-charts .charts .form-switch .form-check-label {
    padding-left: 10px;
    line-height: 28px;
    font-weight: 500;
    font-size: 16px;
    color: #383874;
}

.actual-non-actual-charts .charts {
    margin-bottom: 40px;
}

.actual-non-actual-charts .charts .pie-chart canvas {
    width: 500px !important;
    height: auto !important;
    display: block;
}

.actual-non-actual-charts .charts .bar-chart canvas {
    width: 100%;
}

.actual-non-actual-charts .speed-charts {
    border: none;
    box-shadow: 0px 100px 80px 0px #6C49AC12;
    padding: 20px 24px 50px;
}

.actual-non-actual-charts .speed-charts .title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #383874;
}

.actual-non-actual-charts .speed-charts p {
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: #383874;
    margin-top: 5px;

}

.actual-non-actual-charts .speed-charts .form-switch .form-check-input {
    margin-right: 15px;
    width: 37px;
    cursor: pointer;
    background-color: #B7B7B7;
    height: 17px;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

.actual-non-actual-charts .speed-charts .form-switch .form-check-input:checked {
    background-color: #384CFF;
}

@media (max-width: 992px) {
    .tabs-container {
        width: 100%;
    }

    .tabs-container.tabs-sticky .nav-pills {
        width: 696px;
    }

    .tabs-charts.sticky,
    .tabs-storage-charts.sticky,
    .tabs-network-charts.sticky,
    .tabs-managed-charts.sticky {
        width: 696px;
    }
}

@media (max-width: 771px) {
    .tabs-container {
        width: 100%;
    }

    .tabs-container.tabs-sticky .nav-pills {
        width: 516px;
    }

    .tabs-container .nav-pills .nav-item .nav-link {
        font-size: 16px;
        line-height: 18px;
        height: 52px;
        text-align: center;
    }

    .tabs-charts.sticky,
    .tabs-storage-charts.sticky,
    .tabs-network-charts.sticky,
    .tabs-managed-charts.sticky {
        width: 516px;
    }

    .tabs-container .optimization-option-tab .nav-pills .nav-item .nav-link {
        font-size: 14px;
        line-height: 18px;
    }

    .tabs-container .optimization-option-tab .tab-content .bottom-contain span {
        width: 70%;
        font-size: 14px;
        line-height: 18px;
    }

    .tabs-container .optimization-option-tab .tab-content .bottom-contain .impact-details {
        width: 30%;
        font-size: 16px;
        line-height: 18px;

    }




}

@media (max-width: 556px) {
    .tabs-container.tabs-sticky .optimization-option-tab .nav-pills {
        top: 116px;
    }

    .tabs-container.tabs-sticky .nav-pills {
        width: calc(100% - 1.5rem);
    }

    .tabs-charts.sticky,
    .tabs-storage-charts.sticky,
    .tabs-network-charts.sticky,
    .tabs-managed-charts.sticky {
        width: calc(100% - 1.5rem);
        top: 153px;
    }

    .tabs-container .nav-pills .nav-item .nav-link {
        font-size: 12px;
        height: 36px;
        line-height: 15px;
    }

    .tabs-container .nav-pills .nav-item .nav-link svg {
        width: 15px;
    }

    .tabs-container .optimization-option-tab .tab-content .top-contain {
        padding: 15px;
    }

    .tabs-container .optimization-option-tab .tab-content .top-contain span {
        font-size: 16px;
        line-height: 20px;
    }

    .tabs-container .optimization-option-tab .tab-content .bottom-contain {
        padding: 15px;
    }

    .tabs-container .optimization-option-tab .tab-content .bottom-contain span {
        width: 70%;
        font-size: 12px;
        line-height: 16px;
    }

    .tabs-container .optimization-option-tab .tab-content .bottom-contain .impact-details {
        width: 30%;
        font-size: 14px;
        line-height: 16px;
    }

    .tabs-container .optimization-option-tab .tab-content .compute-total-cost span {
        font-size: 16px;
    }

    .tabs-container .optimization-option-tab .tab-content .compute-total-cost .btn {
        font-size: 14px;
    }

    .charts-shadow {
        margin-left: -1.5rem;
        width: calc(100% + 3rem);
        margin-top: 10px;
    }

    .tabs-container .content .sub-heading {
        font-size: 16px;
    }

    .tabs-container .content .link {
        font-size: 14px;
    }

    .tabs-container .tab-content .tab-pane .final-impact-part .scenario-total-details span {
        font-size: 12px;
        padding-right: 10px;
    }

    .tabs-container .tab-content .tab-pane .final-impact-part>div {
        flex-wrap: wrap;
    }

    .tabs-container .tab-content .tab-pane .final-impact-part .impact-details {
        font-size: 12px;
    }

    .tabs-container .tab-content .tab-pane .final-impact-part .other-services-info p {
        width: 100%;
    }

    .tabs-charts.sticky+.apply-boxes,
    .tabs-storage-charts.sticky+.apply-boxes,
    .tabs-network-charts.sticky+.apply-boxes,
    .tabs-managed-charts.sticky+.apply-boxes {
        margin-top: 70px;
    }


}